// extracted by mini-css-extract-plugin
export var ArtistDescription = "DondiWhite-module--ArtistDescription--j7v7t";
export var ArtistInfos = "DondiWhite-module--ArtistInfos--YFNI4";
export var ButtonWrapper = "DondiWhite-module--ButtonWrapper --xOn9y";
export var CardWrapper = "DondiWhite-module--CardWrapper--9ox-6";
export var CarrouselWrapper2 = "DondiWhite-module--CarrouselWrapper2--r+bzG";
export var Citations = "DondiWhite-module--Citations--LzrMu";
export var DescriptionWrapper = "DondiWhite-module--DescriptionWrapper--9iX0x";
export var ImageWrapper = "DondiWhite-module--ImageWrapper--fVHp-";
export var LinkWrapper = "DondiWhite-module--LinkWrapper--00aZu";
export var MobileProtrait = "DondiWhite-module--MobileProtrait--j1xid";
export var More = "DondiWhite-module--More--YTBFh";
export var MoreButton = "DondiWhite-module--MoreButton--9V9mn";
export var NameWrapper = "DondiWhite-module--NameWrapper--8fWQh";
export var PdpWrapper = "DondiWhite-module--PdpWrapper--YU6cY";
export var PhotosWrapper = "DondiWhite-module--PhotosWrapper--A7RZF";
export var ProfilWrapper = "DondiWhite-module--ProfilWrapper--zKNzN";
export var TitleWrapper = "DondiWhite-module--TitleWrapper--3OYpk";
export var Wrapper = "DondiWhite-module--Wrapper--WVQQJ";